import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import login from "../Pages/Register/Login";
import MessageBox from "./MessageBox";
import ScrollButton from "./ScrollButton";
function Fixtures(props) {
  let { fixtures } = props;
  const handleFixtureClick = (fixture) => {
    props?.history.push(`/fixture-details/show?id=${fixture._id}`);
  };
  const [leagues, setLeagues] = useState([]);


  useEffect(() => {
    let allLeagues = fixtures?.map((f) => f?.tournament?.bb_id);
    allLeagues = [...new Set(allLeagues)];
    setLeagues(allLeagues);
  }, [fixtures]);


  return (
      <>
        <div className="BLM-leagueBox-group">
          <div
              className="BLM-leagueBox BLM-accordion"
              id="getUpcomingAccordianId_0"
          >
            <div
                className="BLM-leagueBox-header BLM-accordion-header"
                id="upcoming_1"
            >
              <div className="col-lg-4"></div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="BLM-leagueBox-header__marketInfo">
                      <div className="BLM-marketGroup">
                        <div className="row">
                          <div className="col-lg-4"></div>
                          <div className="col-lg-4">
                            <div className="BLM-marketItem">1 X 2</div>
                          </div>
                          <div className="col-lg-4"></div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="BLM-marketItem">1</div>
                          </div>
                          <div className="col-lg-4">
                            <div className="BLM-marketItem">X</div>
                          </div>
                          <div className="col-lg-4">
                            <div className="BLM-marketItem">2</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="BLM-leagueBox-header__marketInfo">
                      <div className="BLM-marketGroup">
                        <div className="row">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8 text-center">
                              <span className="BLM-marketItem">
                                Double Chance
                              </span>
                          </div>
                          <div className="col-lg-2"></div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 text-center">
                            <div className="BLM-marketItem">1 OR X</div>
                          </div>
                          <div className="col-lg-3 text-center">
                            <div className="BLM-marketItem">X OR 2</div>
                          </div>
                          <div className="col-lg-4 text-center">
                            <div className="BLM-marketItem">2 OR X</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="BLM-leagueBox-header__marketInfo">
                      <div className="BLM-marketGroup">
                        <div className="row">
                          <div className="col-lg-3"></div>
                          <div className="col-lg-4 text-center">
                            <div className="BLM-marketItem">
                            BTTS</div>
                          </div>
                          <div className="col-lg-3"></div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 text-center">
                            <div className="BLM-marketItem">Yes</div>
                          </div>
                          <div className="col-lg-6 text-center">
                            <div className="BLM-marketItem">No</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {leagues?.map((league, index) => {
          if (typeof(league) !== 'undefined' && league != null && fixtures?.find((f) => f.tournament_bb_id == league)) {
            return (
                <div className="fixtures_container">
                  <div className="BLM-leagueBox-group mt-1" key={index}>
                    <div
                        className="BLM-leagueBox BLM-accordion"
                        id={`accordian_${index}`}
                    >
                      <div
                          className="BLM-leagueBox-header BLM-accordion-header BLM-arrowBefore"
                          onClick={() => {
                            $(`#accordian_${index}`).toggleClass("collapsed");
                          }}>
                        <div className="col-lg-12">
                          <div className="BLM-leagueBox-header__leagueInfo">
                            <span className="BLM-leagueName">
                               {
                                 fixtures?.find((f) => f.tournament_bb_id == league)
                                     ?.tournament?.category?.name
                               }
                              {
                                fixtures?.find((f) => f.tournament_bb_id == league)
                                    ?.tournament?.live_category?.name
                              }
                              -
                              {
                                fixtures?.find((f) => f.tournament_bb_id == league)
                                    ?.tournament?.name
                              }

                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-collapse collapse show BLM-accordion-content rounded">
                        <div className="BLM-leagueBox-content">
                          {/* SECOND LOOP */}
                          {fixtures
                              .filter((f) => f.tournament_bb_id == league)
                              .map((fixture) => {
                                const participantOne = fixture.competitors[0];
                                const participantTwo = fixture.competitors[1];
                                if(fixture.competitors && fixture.competitors.length != 0 && fixture?.fixture_markets[0]?.fixture_market_odds[0]?.reason!="CANCELLED_EVENT" &&  fixture?.fixture_markets[0]?.fixture_market_odds[0]?.value!=null) {
                                  return (
                                      <div className="BLM-matchBox" id={1}>
                                        <div className="BLM-matchDetails-container row">
                                          <div className="col-lg-4">
                                            <div
                                                className="BLM-matchDetails"
                                                onClick={() => {
                                                  handleFixtureClick(fixture);
                                                }}
                                            >
                                              <div className="BLM-matchBox__fixtureInfo">
                                                <div className="BLM-match__teamsInfo">
                                                  <div className="BLM-match__teamName home truncate">
                                                    {participantOne?.name}
                                                  </div>
                                                  <div className="BLM-match__teamName away truncate">
                                                    {participantTwo?.name}
                                                  </div>
                                                </div>
                                                <div className="BLM-match__scoreInfo">
                                                  <div className="BLM-match__score">
                                        <span className="BLM-score active">
                                          {fixture?.livescore?.scoreboard?.results?.find(
                                              (r) => r.position == "1"
                                          )?.value ?? ""}
                                        </span>
                                                  </div>
                                                  <div className="BLM-match__score">
                                        <span className="BLM-score active">
                                          {fixture?.livescore?.scoreboard?.results?.find(
                                              (r) => r.position == "2"
                                          )?.value ?? ""}
                                        </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-8">
                                            <div className="row">
                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div
                                                      className="btn-group"
                                                      role="group"
                                                      aria-label="Basic example"
                                                  >
                                                    {props?._renderOddsData(fixture, "Match Winner")}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div
                                                      className="btn-group"
                                                      role="group"
                                                      aria-label="Basic example"
                                                  >
                                                    {props?._renderOddsData(
                                                        fixture,
                                                        "Double Chance"
                                                    )}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-lg-4">
                                                <div className="row">
                                                  <div
                                                      className="btn-group1"
                                                      role="group"
                                                      aria-label="Basic example"
                                                  >
                                                    {props?._renderOddsData(
                                                        fixture,
                                                        "Both Teams To Score"
                                                    )}
                                                  </div>
                                                  {/*<div*/}
                                                  {/*    className="btn-group"*/}
                                                  {/*    role="group"*/}
                                                  {/*    aria-label="Basic example"*/}
                                                  {/*>*/}

                                                  {/*</div>*/}

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="BLM-matchBox__extraInfo">
                              <span
                                  className="BLM-match__kickOff"
                                  eventid="home_upcoming_4042486"
                              >
                                {new Date(
                                    parseInt(fixture.match_date.$date.$numberLong)
                                ).toLocaleString()}
                              </span>
                                          <span className="BLM-match__smsCode">
                                #<b>{fixture?.bb_id}</b>
                              </span>
                                          <span className="BLM-match__marketsCount">
                              +
                                            {fixture?.fixture_market_count?.length ??
                                            fixture?.fixture_markets?.length ??
                                            ""}{" "}
                                            markets
                            </span>
                                        </div>
                                      </div>
                                  );
                                }
                              })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            );
          }
        })}
        {props.next_page_url != null && (
            <div
                onClick={() => {
                  props.setTrigger(props.trigger + 1);
                }}
            >
              <MessageBox data={props.loadingMessage}/>
            </div>
        )}
        <ScrollButton />
      </>
  );
}

export default withRouter(Fixtures);
