import "bootstrap/dist/css/bootstrap.css";
import { AppLayout } from "../../../layouts/AppLayout";
import { Betslips } from "../../Common/Betslips";
import axios from "axios";
import { API_URL } from "../../Common/constants";
import _ from "lodash";
import { get } from "lodash";
import "./sports.css";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../Common/Header";
import Filters from "../../Common/Filters";
import Fixtures from "../../Common/Fixtures";
import Loader from "../../Common/Loader";
import {
  getFixturesBySportIdCall,
  getAllLeaguesBySport,
  getAllSportsCall,
  getFixtureResultCall
} from "../../Common/repository";
import { setBets } from "../../../store/Betslip/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ResultFixture from "../../Common/ResultFixture";
import {logDOM} from "@testing-library/react";


const Sports = (props) => {
  const [activeSportId, setActiveSportId] = useState(null);
  const [activeLeagueId, setActiveLeagueId] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [leaguesHeader, setLeaguesHeader] = useState([]);
  const [sports, setSports] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [matchTime, setMatchTime] = useState("");
  let dispatch = useDispatch();
  const Bets = useSelector((state) => state?.Betslip?.bets ?? []);
  const [marketChange, setMarketChange] = useState(null);
  const [sport, setSportData] = useState(null);
  const [active_tab, set_active_tab] = useState("Upcoming");
  const [next_page_url, set_next_page_url] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("Load More Data");
  const [resultTab, setResultTab] = useState("today");
  useEffect(() => {
    fetchData()
  }, [trigger]);

  useEffect(() => {
    // setLoading(true);
    // $("#main-container").css({ opacity: 0.6 });
    axios.get(`${API_URL}/v4/leagues?perPage=1000`).then((res) => {
      //get All Leagues
      setLeagues(res?.data?.data?.data);
      axios
          .get(`${API_URL}/v4/sports`)
          .then((res) => {
            let _activeSportId = res.data.data.data.find(
                (item) => item.name == "Soccer"
            )._id;
            //get All Sports
            setSports(res.data.data.data);
            setActiveSportId(
                res.data.data.data.find((item) => item.name == "Soccer").id
            );
          })
          .catch((error) => console.log(error))
          .finally(() => {
          });
    });
  }, []);

  useEffect(() => {
    if (activeSportId) {
      getAllSportsCall().then((res) => {
        setSportData(res?.data?.data?.data??[]);
      })
          .catch((e) => {
          })
          .finally(() => {
            // setLoading(false);
          });
      getFixturesBySportId();
    }
  }, [activeSportId]);

  useEffect(() => {
    if (activeSportId) {
      getAllLeaguesBySport(
          activeSportId,
          successGetLeaguesBySport,
          errorGetLeaguesBySport
      ) .catch((e) => {
      });
    }
  },activeSportId);

  useEffect(() => {
    if (activeSportId) {
      setMarketChange(
          _.sortBy(
              sport?.find((s) => s.id == activeSportId)?.many_markets,
              "pivot._id"
          )[0]?.id
      );
    }
  }, [activeSportId, sport]);
  useEffect(() => {
    setMarketChange(marketChange);
  }, [marketChange]);

  useEffect(() => {
    setMarketChange(marketChange);
  }, [marketChange]);

  const successGetLeaguesBySport = (d) => {
    setLeaguesHeader(d);
  };
  const errorGetLeaguesBySport = () => {
  };

  const getLeagueFixtures = (id,filter="All",bb_ids="") => {
    if (id) {
      setLoading(true);
      setLoadingMessage("Loading Data...");
      const url=`/v4/leagues/${id}/fixtures`;
      getFixturesBySportIdCall(url,filter,marketChange,bb_ids)
          .then((res) => {
            setFixtures(res?.data?.data?.data);
            set_next_page_url(res?.data?.data?.next_page_url)

            if (res?.data?.data?.data?.length == 0)
              setLoadingMessage("No Data Available");
          })
          .catch((e) => {})
          .finally(() => {
            setLoadingMessage("Load More Data");
            setLoading(false);
          });
    }
  };
  const getFixturesBySportId = (id,filter="All") => {
    id=id??1;
    setLoading(true);
    setLoadingMessage("Loading Data...");
    const url=`/v4/sports/${id}/fixtures`;
    getFixturesBySportIdCall(url,filter,marketChange)
        .then((res) => {
          setFixtures(res?.data?.data?.data);
          set_next_page_url(res?.data?.data?.next_page_url)
          if (res?.data?.data?.data?.length == 0)
            setLoadingMessage("No Data Available");
        })
        .catch((e) => {}).finally(() => {
      setLoadingMessage("Load More Data");
      setLoading(false);
    });

  };

  const getResultOfFixture = (id,filter="All") => {
    setLoading(true);
    setLoadingMessage("Loading Data...");

    getFixtureResultCall(id,'all',filter)
        .then((res) => {
          setFixtures(res?.data?.data?.data);
          set_next_page_url(res?.data?.data?.next_page_url)
          if (res?.data?.data?.data?.length == 0)
            setLoadingMessage("No Data Available");
        })
        .catch((e) => {}).finally(() => {
      setLoadingMessage("Load More Data");
      setLoading(false);
    });

  };

  const filterClickChange = () => {
    const days_filter=document.getElementById("ddn_upcomingdayfilters").value ?? "All";

    if(active_tab=="Upcoming"){
      getFixturesBySportId(activeSportId,days_filter);
    }
    else{
      let bb_ids = leaguesHeader ?.map((l, index) => {
        return l?.bb_id;
      })
      bb_ids.join(",")
      getLeagueFixtures(active_tab,days_filter,bb_ids)
    }
  }

  const headerClickChange = (id) => {
    const days_filter=document.getElementById("ddn_upcomingdayfilters").value ?? "All";
    if(id=="Upcoming"){
      getFixturesBySportId(activeSportId,days_filter);
    }
    else if(id=="TopLeagues"){
      let bb_ids = leaguesHeader ?.map((l, index) => {
        return l?.bb_id;
      })
      bb_ids.join(",")
      getLeagueFixtures(id,days_filter,bb_ids)
    }
    else if(id=="result"){
      getResultOfFixture(activeSportId,resultTab)
    }
  }
  useEffect(()=> {
    if(active_tab=="result"){
      getResultOfFixture(activeSportId,resultTab);
    }

  },[resultTab]);

  const fetchData = () => {
    if (
        ![undefined, null, ""].includes(activeSportId) &&
        next_page_url != null
    ) {
      setLoadingMessage("Loading Data...");

      let bb_ids = "";

      if(active_tab=="TopLeagues"){

        bb_ids = leaguesHeader ?.map((l, index) => {
          return l?.bb_id;
        })
        bb_ids.join(",")
      }
      let url="";
      if(active_tab=="result"){
        url=`${next_page_url}&sport_id=${activeSportId}&match_time=${matchTime}&timezone_offset=${new Date().getTimezoneOffset()}&perPage=10&day=${resultTab}`
      }
      else{
        url=`${next_page_url}&match_time=${matchTime}&timezone_offset=${new Date().getTimezoneOffset()}&perPage=10&leagueIds=${bb_ids}`
      }
      return axios.get(
          url
      ).then((res) => {
        let new_fixtures = get(res, "data.data.data", []);
        let combined_fixtures = fixtures.concat(new_fixtures);

        const unique_fixtures = combined_fixtures.filter((v,i,a)=>a.findIndex(t=>(t._id===v._id))===i)

        setFixtures(unique_fixtures)
        set_next_page_url(get(res, "data.data.next_page_url", null));

        if (res?.data?.data?.data?.length == 0)
          setLoadingMessage("No Data Available");
      })
          .finally(() => {
            setLoadingMessage("Load More Data");
          });
    }
  };


  const _addToBetslip = (fixture, fixtureMarket, odd) => {
    let copyFixture = _.cloneDeep(fixture);
    let oddsData = copyFixture.fixture_markets
        .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
        .fixture_market_odds.map((odd) => {
          odd.selected = false;
          return odd;
        });
    copyFixture.fixture_markets.find(
        (fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id
    ).odds = oddsData;
    copyFixture.fixture_markets
        .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
        .fixture_market_odds.find(
        (oddData) => oddData._id == odd._id
    ).selected = true;

    let foundFixture = Bets.find((fix) => fix._id == fixture._id);
    if (foundFixture) {
      let d = _.cloneDeep(Bets);
      let index = d.findIndex((i) => i._id == fixture._id);
      if (index > -1) {
        d[index] = copyFixture;
      }
      dispatch(setBets(d));
    } else {
      dispatch(setBets([...Bets, copyFixture]));
    }
  };

  const checkIfOddIsSelected = (f, fm, odd) => {
    if (Bets.length && odd) {
      return (
          Bets?.find((b) => b._id == f._id)
              ?.fixture_markets?.find((fmd) => fmd._id == fm._id)
              ?.odds?.find((o) => o._id == odd._id)?.selected ?? false
      );
    }
    return false;
  };

  const _removeOddFromBetslip = (fixture) => {
    dispatch(setBets(_.cloneDeep(Bets).filter((fd) => fd._id != fixture._id)));
  };

  const _renderOddsData = (fixture, marketName) => {
    let odds = null;
    let showOdd = true;
    let tempFixture = fixture.fixture_markets.find(
        (fixtureMarket) => fixtureMarket.market.name == marketName
    );
    if (tempFixture && tempFixture.fixture_market_odds.length) {
      odds = tempFixture.fixture_market_odds;
    }
    if (odds) {
      return odds?.map((odd, i) => {
        let text  = odd?.market_spec?.name;

        text  = text?.replaceAll("Goals Over Under",'').trim();

        if (marketName == "Goals Over Under" && odd?.special_bet_value != "2.5") {
          showOdd = false;
        }
        if((text=="over" || text=="under") && odd?.special_bet_value=="2.5"){
          showOdd = true;
        }

        if (showOdd) {
          return (
              <>
                <button
                    key={i}
                    type="button"
                    className={`odd-btn ${
                        checkIfOddIsSelected(fixture, tempFixture, odd)
                            ? `btn btn-primary ml-1`
                            : `btn btn-light ml-1
                ${odd?.value == "OFF" ? "disabled" : ""}`
                    }`}
                    disabled={odd ? (odd.value == "OFF" ? true : false) : true}
                    onClick={() => {
                      if (checkIfOddIsSelected(fixture, tempFixture, odd)) {

                        _removeOddFromBetslip(fixture);
                      } else {
                        _addToBetslip(fixture, tempFixture, odd);
                      }
                    }}
                >
                  {odd?.value == "OFF" ? "-" : parseFloat(odd?.value)?.toFixed(2)}
                </button>
              </>
          );
        }
      });
    }

  };

  return (
      <AppLayout>
        <div className="row container_layout">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-12">
                <Header
                    activeLeagueId={activeLeagueId}
                    setActiveLeagueId={setActiveLeagueId}
                    leagues={leaguesHeader}
                    getLeagueFixtures={getLeagueFixtures}
                    setActiveSportId={setActiveSportId}
                    activeSportId={activeSportId}
                    setMarketChange={setMarketChange}
                    getSportFixtures={getFixturesBySportId}
                    headerClickChange={headerClickChange}
                    set_active_tab={set_active_tab}
                />
              </div>
            </div>
            <div className="row">
              <Filters
                  setFixtures={setFixtures}
                  loading={loading}
                  setLoading={setLoading}
                  matchTime={matchTime}
                  setMatchTime={setMatchTime}
                  filterClickChange={filterClickChange}
                  active_tab={active_tab}
                  resultTab={resultTab}
                  setResultTab={setResultTab}
              />
            </div>

            {loading ? (
                <Loader />
            ) : (
                (active_tab=="result") ?
                    <ResultFixture
                        fixtures={fixtures}
                        activeLeagueId={activeLeagueId}
                        _renderOddsData={_renderOddsData}
                        set_next_page_url={set_next_page_url}
                        next_page_url={next_page_url}
                        getSportFixtures={getFixturesBySportId}
                        headerClickChange={headerClickChange}
                        fetchData={fetchData}
                        setTrigger={setTrigger}
                        trigger={trigger}
                        loadingMessage={loadingMessage}

                    /> :
                    <Fixtures
                        fixtures={fixtures}
                        activeLeagueId={activeLeagueId}
                        _renderOddsData={_renderOddsData}
                        set_next_page_url={set_next_page_url}
                        next_page_url={next_page_url}
                        getSportFixtures={getFixturesBySportId}
                        headerClickChange={headerClickChange}
                        fetchData={fetchData}
                        setTrigger={setTrigger}
                        trigger={trigger}
                        loadingMessage={loadingMessage}

                    />
            )}
          </div>
          <div className="col-lg-3">
            <Betslips />
          </div>
        </div>
      </AppLayout>
  );
};
export default withRouter(Sports);
